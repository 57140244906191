<template>
  <v-row v-if="categories.length > 0">
    <v-col
      cols="12"
      md="4"
    >
      <SectionTitle title="Categorias" />
      <v-treeview
        v-model="product.categories"
        selectable
        selection-type="independent"
        :items="mainCategories"
        return-object
        open-on-click
        item-children="subcategories"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <SectionTitle title="Segmentos" />
      <v-treeview
        v-model="product.segments"
        selectable
        :items="segments"
        return-object
        selection-type="independent"
        item-children="subcategories"
        open-on-click
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <SectionTitle title="Datas Especiais" />
      <v-treeview
        selectable
        v-model="product.special_dates"
        :items="special_dates"
        return-object
        selection-type="independent"
        item-children="subcategories"
        open-on-click
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
  },
  data: () => ({
    categories: [],
  }),
  computed: {
    mainCategories: function () {
      return this.categories.filter(
        (el) => el.category_id === null && el.type === 1
      )
    },
    segments: function () {
      return this.categories.filter(
        (el) => el.category_id === null && el.type === 2
      )
    },
    special_dates: function () {
      return this.categories.filter(
        (el) => el.category_id === null && el.type === 3
      )
    },
  },
  methods: {
    getCategories() {
      this.$api
        .get('product_categories/show_product')
        .then((res) => {
          this.categories = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getCategories()
  },
}
</script>